@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply bg-yellow-400 px-4 rounded text-2xl text-white font-bold;
  }
}

image,
img {
  image-rendering: -webkit-optimize-contrast;
}
